export const trialLessons = [
   {
     type: "en",
     lesson: [
       {
         name: "kids",
         url: "https://www.canva.com/design/DAGOSuI6pZs/bvLCybHhiM2w_12Isu_wuA/view?utm_content=DAGOSuI6pZs&utm_campaign=designshare&utm_medium=link&utm_source=editor",
       },
       {
         name: "teenagers",
         url: "https://www.canva.com/design/DAGB7gy39yo/SSWqGAI-YcTjs1iijsWlFw/view?utm_content=DAGB7gy39yo&utm_campaign=designshare&utm_medium=link&utm_source=editor",
       },
       {
         name: "adults",
         url: "https://www.canva.com/design/DAGCBJSLUFY/xJoQxxOy8WkFoo9NM-mNIw/view?utm_content=DAGCBJSLUFY&utm_campaign=designshare&utm_medium=link&utm_source=editor",
       },
     ],
   },
   {
     type: "de",
     lesson: [
       {
         name: "kids",
         url: "https://www.canva.com/design/DAGeptfQjtI/qmRoKID21_AJwheL-zDIHg/view?utm_content=DAGeptfQjtI&utm_campaign=designshare&utm_medium=link2&utm_source=uniquelinks&utlId=h747799b5eb",
       },
       {
         name: "teenagers",
         url: "https://www.canva.com/design/DAGfGtbK-rA/mGy25DZZnK6AIfBUXUNHIA/view?utm_content=DAGfGtbK-rA&utm_campaign=designshare&utm_medium=link2&utm_source=uniquelinks&utlId=hccd8e92f45",
       },
       {
         name: "adults",
         url: "https://www.canva.com/design/DAGfpjtBQEc/00cT5uuCXTkOrR2TXZYWzA/view?utm_content=DAGfpjtBQEc&utm_campaign=designshare&utm_medium=link2&utm_source=uniquelinks&utlId=h5643e479b8",
       },
     ],
   },
   {
     type: "pl",
     lesson: [
       {
         name: "kids",
         url: "https://www.canva.com/design/DAGOSuI6pZs/bvLCybHhiM2w_12Isu_wuA/view?utm_content=DAGOSuI6pZs&utm_campaign=designshare&utm_medium=link&utm_source=editor",
       },
       {
         name: "teenagers",
         url: "https://www.canva.com/design/DAGB7gy39yo/SSWqGAI-YcTjs1iijsWlFw/view?utm_content=DAGB7gy39yo&utm_campaign=designshare&utm_medium=link&utm_source=editor",
       },
       {
         name: "adults",
         url: "https://www.canva.com/design/DAGCBJSLUFY/xJoQxxOy8WkFoo9NM-mNIw/view?utm_content=DAGCBJSLUFY&utm_campaign=designshare&utm_medium=link&utm_source=editor",
       },
     ],
   },
 ];